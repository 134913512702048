export var Translations = {
  en: {
    login_page:                {
      welcome:                       'Welcome back',
      dont_have_account:             'Don\'t have account?',
      create_account:                'Create an account',
      login_button:                  'Sign in',
      email_label:                   'Email',
      email_placeholder:             'name@company.com',
      password_label:                'Password',
      password_placeholder:          'password',
      login_error_wrong_credentials: 'The email address or password you entered is incorrect.',
    },
    ads_page:                  {
      page_header_title:       'Ads',
      page_header_subtitle:    'Create and manage your ads.',
      empty_state_add:         'Add your first ad',
      add_new_ad_cta:          'Add new ad',
      add_new_adschedule_cta:  'Add new ad schedule',
      col_t_name:              'Name',
      col_t_standard:          'Standard',
      col_t_format:            'Format',
      col_t_actions:           'Actions',
      actions_edit:            'Edit',
      actions_delete:          'Delete',
      modal_confirm_delete_t:  'Delete',
      modal_confirm_delete_c:  'Are you sure you want to delete',
      modal_confirm_delete_ok: 'ad deleted.',
    },
    embed_page:                {
      added:                    'Embed added.',
      changes_saved:            'Changes saved.',
      page_header_title:        'Embed codes',
      page_header_subtitle:     'Create and manage your embed codes.',
      add_new_embed:            'Generate embed code',
      col_t_name:               'Name',
      col_t_player:             'Player',
      col_t_ad:                 'Ad',
      col_t_video:              'Video',
      col_t_playlist:           'Playlist',
      col_t_created:            'Created',
      col_t_modified:           'Modified',
      col_t_actions:            'Actions',
      actions_edit:             'Edit',
      actions_delete:           'Delete',
      modal_confirm_delete_t:   'Delete',
      modal_confirm_delete_c:   'Are you sure you want to delete',
      modal_confirm_delete_ok:  'embed deleted.',
      get_embed_code_link:      'Get code',
      empty_state_add:          'Add your first embed code.',
      button_copy_code:         'Get code',
      notification_code_copied: 'Code snippet copied to clipboard.',
    },
    embed_form:                {
      page_header_title_add:        'New embed code',
      modal_confirm_delete_t:       'Delete',
      modal_confirm_delete_c:       'Are you sure you want to delete',
      modal_confirm_delete_ok:      'embed code deleted.',
      page_header_action_delete:    'Delete',
      page_header_action_cancel:    'Cancel',
      page_header_action_save:      'Save',
      page_header_action_close:     'Close',
      input_l_name:                 'Embed code name',
      input_l_player:               'Player',
      input_l_code:                 'Code snippet',
      toggle_l_type:                'Item',
      input_l_video:                'Video',
      input_l_placeholder_player:   'Select player',
      input_l_placeholder_video:    'Select video',
      input_l_placeholder_playlist: 'Select playlist',
      input_l_playlist:             'Playlist',
      button_generate_code:         'Generate embed code',
      button_copy_code:             'Copy',
      notification_code_copied:     'Code snippet copied to clipboard.',
    },
    playlists_page:            {
      page_header_title:       'Playlists',
      page_header_subtitle:    'Create and manage your playlists.',
      empty_state_add:         'Add your first playlist',
      add_new_playlist:        'Add new playlist',
      col_t_name:              'Name',
      col_t_standard:          'Standard',
      col_t_format:            'Format',
      col_t_created:           'Created',
      col_t_actions:           'Actions',
      actions_edit:            'Edit',
      actions_delete:          'Delete',
      modal_confirm_delete_t:  'Delete',
      modal_confirm_delete_c:  'Are you sure you want to delete',
      modal_confirm_delete_ok: 'Playlist deleted.',
      playlist_added:          'Playlist added.',
      changes_saved:           'Playlist saved.',
    },
    playlist_form:             {
      input_action_delete:              'Delete',
      page_header_title_add:            'New playlist',
      modal_confirm_delete_t:           'Delete',
      modal_confirm_delete_c:           'Are you sure you want to delete',
      modal_confirm_delete_ok:          'Playlist deleted.',
      page_header_action_delete:        'Delete',
      page_header_action_cancel:        'Cancel',
      page_header_action_save:          'Save',
      input_l_name:                     'Playlist name',
      video_panel:                      'Videos',
      playlist_panel:                   'Playlist',
      items_l:                          'Items',
      select_videos_validation_message: 'Please add videos to your playlist.',
    },
    users_page:                {
      page_header_title:       'Veedmo accounts',
      page_header_subtitle:    'Visible only to Veedmo admins, shows all panel accounts.',
      add_new_user:            'Add new user',
      col_t_name:              'Full name',
      col_t_username:          'Email',
      col_t_phone:             'Phone number',
      col_t_website:           'Website',
      col_t_role:              'Role',
      col_t_status:            'Status',
      col_t_actions:           'Actions',
      actions_edit:            'Edit',
      actions_context:         'Account login',
      modal_confirm_context_t: 'Log in to another account',
      modal_confirm_context:   'You will be logged out from this account. Are you sure you want to log in to another account?',
    },
    veedmo_settings_page:      {
      page_header_title:    'Veedmo global settings',
      page_header_subtitle: 'Visible only to Veedmo admins, shows all panel accounts. Please use with caution!',
      col_t_name:           'Name',
      col_t_value:          'Value',
      col_t_actions:        'Actions',
      actions_add:          'Add setting',
      actions_edit:         'Edit',
      changes_saved:        'Changes saved.',
    },
    veedmo_settings_form:      {
      input_l_name:              'Name of setting',
      input_l_value:             'Value',
      page_header_action_cancel: 'Cancel',
      page_header_action_save:   'Save',
      page_header_title_add:     'Add new setting',
      setting_added:             'New setting added',

    },
    users_edit_page:           {
      changes_saved: 'Changes saved.',
    },
    users_form:                {
      input_l_username:             'Email',
      input_l_name:                 'Full name',
      input_l_phone:                'Phone number',
      input_l_website:              'Website',
      input_l_role:                 'Role',
      input_l_password_placeholder: 'If left empty, password won\'t change',
      input_l_status:               'Account status',
      input_l_password:             'Password',
      input_l_settings:             'Additional settings (you can select many)',
      page_header_action_cancel:    'Cancel',
      page_header_action_save:      'Save',

    },
    ad_add_page:               {
      ad_added: 'Ad added.',
    },
    ad_edit_page:              {
      changes_saved: 'Changes saved.',
    },
    ad_schedules_add_page:     {
      ad_added: 'Ad schedule added.',
    },
    ad_schedules_edit_page:    {
      changes_saved: 'Changes saved.',
    },
    ad_form:                   {
      ad_schedule_ads_empty:          'At least one ad tag should be added.',
      ad_schedule_timeoffset_example: 'Acceptable time formats: XX% (10%), MM:SS (00:12), start, end.',
      ad_schedule_timeoffset_format:  'Acceptable time formats: XX% (10%), MM:SS (00:12), start, end.',
      ad_schedule_ads_count_info:     'If you order your ad tags based on CPM, we recommend to use one ad slot of given type (e.g. one preroll), order your ad tags in slot and define how many ads player should play from slot.',
      add_schedules_error:            'Please add at least one ad break.',
      add_links_to_ad_files_error:    'Please add at least one ad tag.',
      desktop_input_l:                'Desktop',
      desktop_tooltip_text:           'This ads will be used to play on desktop devices. If empty, no ads will be used for desktop devices.',
      mobile_input_l:                 'Mobile',
      mobile_tooltip_text:            'This ads will be used to play on mobile devices. If empty, no ads will be used for mobile devices.',
      desktop_mobile_input_l:         'Desktop and mobile',
      desktop_mobile_tooltip_text:    'This ads will be used to play on desktop and mobile devices. If empty, no ads will be used for desktop and mobile devices.',
      input_action_delete:            'Delete',
      validator:                      'Field must be an URL or string.',
      action_add_waterfall:           'Add an ad tag',
      action_add_ad_schedule:         'Add an ad break',
      page_header_title_add:          'New ad',
      page_header_title_add_schedule: 'New ad schedule',
      modal_confirm_delete_t:         'Delete',
      modal_confirm_delete_c:         'Are you sure you want to delete',
      modal_confirm_delete_ok:        'ad deleted.',
      page_header_action_delete:      'Delete',
      page_header_action_cancel:      'Cancel',
      page_header_action_save:        'Save',
      input_l_ad_name:                'Ad name',
      checkbox_l_reuse_tags:          'Use the same ad tags on desktop and mobile',
      input_l_ad_schedule_name:       'Ad schedule name',
      section_header_ad_setup:        'Ad setup',
      toggle_l_ad_standard:           'Ad standard',
      toggle_l_ad_format:             'Ad format',
      toggle_ad_schedule:             'Ad schedule',
      select_ad_schedule_ad_break:    'Ad break',
      select_tag_type_vast:           'VAST/VMAP URL',
      select_tag_type_only_vast:      'VAST URL',
      select_tag_type_vpaid:          'VPAID URL',
      select_tag_type_vastxml:        'XML',
      break_time_l:                   'Break timing',
      ads_count_l:                    'Number of ads to play in slot',
    },
    home_page:                 {
      modal_change_password_title:              'Change Password',
      modal_change_password_ok:                 'Change password',
      modal_change_password_cancel:             'Cancel',
      modal_change_password_old_password_wrong: 'Please provide proper old password',
      modal_change_password_success:            'The password was changed',
      modal_change_password_old_password:       'Old password',
      modal_change_password_new_password:       'New password',
      modal_change_password_new_2_password:     'Re-enter new password',
    },
    analytics_page:            {
      page_header_title:                        'Home',
      page_header_subtitle:                     'Overview of your videos and ads performance.',
      page_header_analytics_filter_platform:    'Platform',
      page_header_analytics_filter_platform_dm: 'Desktop and mobile',
      page_header_analytics_filter_platform_d:  'Desktop',
      page_header_analytics_filter_platform_m:  'Mobile',
      page_header_analytics_filter_date:        'Date range',
      page_header_analytics_export:             'Export stats',
      page_header_analytics_export_ads:         'Export ads stats',
      page_header_analytics_export_ad_tags:     'Export ad tag detailed stats',
      page_header_analytics_export_videos:      'Export videos stats',
      render_options_select_all:                'All',
      section_header_videos:                    'Your videos',
      stats_card_videos_loads:                  'Player loads',
      stats_card_tooltip_videos_loads:          'Number of times player, with video, has been loaded on page.',
      stats_card_videos_sticky_close:           'Sticky player closes',
      stats_card_tooltip_videos_sticky_close:   'Number of clicks in close button on floating (sticky) player.',
      stats_card_videos_impressions:            'Impressions',
      stats_card_tooltip_videos_impressions:    'Number of times video has started to play.',
      stats_card_videos_finishes:               'Finishes',
      stats_card_tooltip_videos_finishes:       'Number of times video has been watched till its end.',
      stats_card_videos_errors:                 'Errors',
      stats_card_tooltip_videos_error:          'Number of errors registered for videos.',
      section_header_ads:                       'Your ads',
      stats_card_ads_impressions:               'Impressions',
      stats_card_tooltip_ads_impressions:       'Number of times ad has started to play.',
      stats_card_ads_first_impressions:         'First impressions',
      stats_card_tooltip_ads_first_impressions: 'Number of times first ad has started to play.',
      stats_card_ads_viewable_im:               'Viewable impressions',
      stats_card_tooltip_ads_viewable_im:       'Number of times when at least 50% of ad area is visible for at least 2 seconds.',
      stats_card_ads_finishes:                  'Finishes',
      stats_card_tooltip_ads_finishes:          'Number of times ad has been watched till its end.',
      stats_card_ads_vtr:                       'VTR',
      stats_card_tooltip_ads_vtr:               'VTR (View Through Rate) = Finishes / Impressions',
      stats_card_ads_clicks:                    'Clicks',
      stats_card_tooltip_ads_clicks:            'Number of times ad has been clicked.',
      stats_card_ads_skips:                     'Skips',
      stats_card_tooltip_ads_skips:             'Number of times ad has been skipped.',
      stats_card_ads_errors:                    'Errors',
      stats_card_tooltip_ads_errors:            'Number of errors registered for ads.',
      stats_card_ads_empty_callback:            'Run custom JS code',
      stats_card_tooltip_ads_empty_callback:    'Number of times custom JavaScript code was run, when no ad was returned by ad server (configurable in player\'s Ads tab).',
      stats_card_ads_empty:                     'No ad returned',
      stats_card_tooltip_ads_empty:             'Number of times no ad has been returned by ad server.',
      file_no_data_to_export:                   'No data to export.',
      section_header_ads_summary:               'Summary data',
      section_header_ads_details:               'Detailed data',
    },
    analytics_details_page:    {
      title:                             'Ad breaks details',
      empty_data:                        'The is no data',
      col_t_name:                        'Ad tag',
      col_t_name_tooltip:                'The value of ad tag',
      col_t_first_impression:            'First Impressions',
      col_t_first_impression_tooltip:    'Number of times first ad has started to play.',
      col_t_impression:                  'Impressions',
      col_t_impression_tooltip:          'Number of times ad has started to play.',
      col_t_finish:                      'Finishes',
      col_t_finish_tooltip:              'Number of times ad has been watched till its end.',
      col_t_click:                       'Clicks',
      col_t_click_tooltip:               'Number of times ad has been clicked.',
      col_t_skip:                        'Skips',
      col_t_skip_tooltip:                'Number of times ad has been skipped.',
      col_t_ad_error:                    'Errors',
      col_t_ad_error_tooltip:            'Number of errors registered for ads.',
      col_t_ad_empty:                    'No ad returned',
      col_t_ad_empty_tooltip:            'Number of times no ad has been returned by ad server.',
      col_t_ad_empty_callback:           'Run custom JS code',
      col_t_ad_empty_callback_tooltip:   'Number of times custom JavaScript code was run, when no ad was returned by ad server (configurable in player\'s Ads tab).',
      col_t_viewable_impression:         'Viewable impressions',
      col_t_viewable_impression_tooltip: 'Number of times when at least 50% of ad area is visible for at least 2 seconds.',
      col_t_vtr:                         'VTR',
      col_t_vtr_tooltip:                 'VTR (View Through Rate) = Finishes / Impressions',
    },
    player_add_page:           {
      player_added: 'Player added.',
    },
    player_edit_page:          {
      changes_saved: 'Changes saved.',
    },
    player_form:               {
      sample_video_title:                                  'Sample video title',
      page_header_title_add:                               'New player',
      modal_confirm_delete_t:                              'Delete',
      modal_confirm_delete_c:                              'Are you sure you want to delete',
      modal_confirm_delete_ok:                             'player deleted.',
      page_header_action_delete:                           'Delete',
      page_header_action_cancel:                           'Cancel',
      page_header_action_save:                             'Save',
      input_l_player_name:                                 'Player name',
      input_l_vMargin:                                     'Player distance from top / bottom screen edge (in pixels)',
      input_l_hMargin:                                     'Player distance from left / right screen edge (in pixels)',
      input_l_minWidth:                                    'Player min. width (in pixels)',
      input_l_maxWidth:                                    'Player max. width (in pixels)',
      input_l_maxWidthP:                                   'Player width relative to screen width (in %)',
      input_l_heightMobileP:                               'Player height on mobile, relative to screen height (in %)',
      input_l_vMargin_sticky:                              'Sticky player distance from top / bottom screen edge (in pixels)',
      input_l_hMargin_sticky:                              'Sticky player distance from left / right screen edge (in pixels)',
      input_l_minWidth_sticky:                             'Sticky player min. width (in pixels)',
      input_l_maxWidth_sticky:                             'Sticky player max. width (in pixels)',
      input_l_maxWidthP_sticky:                            'Sticky player width relative to screen width (in %)',
      input_l_heightMobileP_sticky:                        'Sticky player height on mobile, relative to screen height (in %)',
      section_header_player_size:                          'Player size',
      player_aspect_ratio:                                 'Player aspect ratio',
      aspect_ratio_auto:                                   'Auto',
      aspect_ratio_16_9:                                   '16:9',
      aspect_ratio_4_3:                                    '4:3',
      aspect_ratio_1_1:                                    '1:1',
      aspect_ratio_9_16:                                   '9:16',
      player_size_responsive:                              'Responsive',
      player_size_fixed:                                   'Fixed',
      input_l_player_width:                                'Player width (in pixels)',
      section_header_playback:                             'Playback',
      select_l_autoplay:                                   'Autoplay',
      select_tooltip_autoplay:                             'If you turn on \'Autoplay\', we recommend to select \'Mute on start\' so your video would not be blocked by web browsers.',
      autoplay_off:                                        'Off',
      autoplay_on:                                         'On',
      autoplay_in_view:                                    'In view',
      section_header_controls:                             'Player controls',
      show_controls:                                       'Show controls',
      mute_on_start:                                       'Mute player on start',
      mute_on_start_tooltip:                               'If you turn on \'Autoplay\', we recommend to select \'Mute on start\' so your video would not be blocked by web browsers.',
      include_seo_metadata:                                'Include SEO metadata from video file',
      include_seo_metadata_info:                           'Player will load on page, SEO metadata of published video file: name, duration, description, video URL, poster URL.',
      block_playback_outside_viewport:                     'Pause playback when player is outside of viewport',
      block_playback_outside_viewport_info:                'Playback is paused when player is not visible to page visitor, and auto resumed when player is visible.',
      pip:                                                 'Picture in picture',
      video_time:                                          'Video time',
      seek_buttons:                                        'Seek buttons',
      seek_back:                                           'Seek back',
      seek_forward:                                        'Seek forward',
      section_header_browser_fixes:                        'Web browser fixes',
      selectbox_ad:                                        'Allow ads to run in this player',
      selectbox_ad_placeholder:                            'Select ad',
      checkbox_adblock:                                    'Block video playback if adblock is enabled',
      checkbox_apply_browser_fixes:                        'Allow player to apply web browser fixes',
      input_l_adblock_msg:                                 'Adblock message',
      section_header_ad_handling:                          'Ad format player will play',
      input_tooltip_adformat:                              'Selecting \'Outstream\' format remember to turn on \'Autoplay\', otherwise your ad won\'t play.',
      input_l_outstream_type:                              'Outstream type player will play',
      input_l_player_position:                             'Screen corner (for player location)',
      input_l_player_position_sticky:                      'Screen corner (for sticky player location)',
      player_position_br:                                  'Bottom right',
      player_position_bl:                                  'Bottom left',
      player_position_tr:                                  'Top right',
      player_position_tl:                                  'Top left',
      input_l_player_behaviour:                            'Player behaviour after in content ad finish to play',
      input_l_close_btn:                                   'Player close button',
      input_l_close_btn_sticky:                            'Sticky player close button',
      tab_ads:                                             'Ads',
      tab_customization:                                   'Customization',
      tab_setup:                                           'Setup',
      section_header_player_position:                      'Player position',
      links_refresh_player:                                'Refresh preview to see your changes',
      position_disabled_tooltip:                           'This player plays outstream ads; in such case player position is set in \'Monetization\' tab.',
      size_disabled_outstream_tooltip:                     'This player plays outstream in slide ads; in such case player size is set in \'Monetization\' tab.',
      size_disabled_screencorner_tooltip:                  'This player has screen corner position; in such case player size is set in \'Player position\' section.',
      pip_disabled_tooltip:                                'Picture-in-picture is available only for player \'Player position = Page content\'.',
      browser_fixes_tooltip:                               'Some web browsers got bugs not allowing player to work properly. By enabling \'Web browser fixes\' player can fix mentioned bugs, but it can also break given website.',
      sectionp_layer_injections:                           'Player injection into page',
      custom_player_injections_tooltip_p1:                 'By default, player will appear on page in place where you paste its code snippet.',
      custom_player_injections_tooltip_p2:                 'You can customize this behaviour, so player would appear on page in differen place than you’ve pasted its code snippet.',
      custom_player_injections_query_selector:             'Query selector of the page element to inject player into',
      custom_player_injections_query_selector_tooltip:     'JavaScript querySelector (e.g. HTML element id or class names).',
      custom_player_injections_inject_method:              'Inject method',
      custom_player_injections_append_child:               'Inside element',
      custom_player_injections_inplace:                    'In place of embedded script',
      custom_player_injections_before_begin:               'Before element',
      custom_player_injections_before_end:                 'After last child of element',
      custom_player_injections_after_begin:                'Before first child of element',
      custom_player_injections_after_end:                  'After element',
      custom_player_injections_query_selector_placeholder: '#element-id .element-class',
      show_players_close_button:                           'Show player close button, with delay (in seconds)',
      use_player_close_button:                             'Use player close button to skip ads',
      section_header_branding_top_right_corner_logo:       'Top-right corner logo',
      section_header_branding_bottom_bar:                  'Bottom bar',
      branding_text:                                       'Text',
      branding_link:                                       'Link',
      branding_image:                                      'Image',
      branding_corner_text_info:                           'Text which will appear on logo hover.',
      branding_corner_link_info:                           'Link which will be opened when user will click on logo.',
      branding_corner_image_info:                          'Image which will appear in player top-right corner. Recommended image size is 48x48px (square).',
      branding_bottom_bar_text_info:                       'Text which will appear on bottom bar.',
      branding_bottom_bar_link_info:                       'Link which will be opened when user will click on text on bottom bar.',
      branding_bottom_bar_image_info:                      'Image which will appear on bottom bar, after text. Recommended image size is 48x48px (square).',
      branding_default:                                    'Default',
      branding_disabled:                                   'Disabled',
      branding_custom:                                     'Custom',
      branding_on:                                         'On',
      branding_off:                                        'Off',
      hide_player_after_ad_finish:                         'For outstream ads, automatically hide player after ad finished playing',
      wait_for_privacy_consent:                            'Delay player to wait for privacy consent (in seconds)',
      custom_player_behaviour:                             'Custom player behaviour depending on ad status',
      custom_player_behaviour_tooltip:                     'If you use VMAPs (multiple ads per one video), we do not recommend to enable below behaviours as they are dedicated to work with single preroll ads.',
      disable_autoplay_if_no_ad:                           'Disable autoplay, if no ad was returned by ad server',
      hide_player_if_no_ad:                                'Hide player on page, if no ad was returned by ad server',
      disable_sticky_if_empty_or_finished:                 'Disable sticky mode, if no ad was returned by ad server, or ad finished to play',
      switch_to_sticky_if_outside_viewport:                'Switch to sticky mode straight after page load, if player is outside user\'s viewport, and ad was returned by ad server',
      run_alternative_player_or_ad_config_if_no_ad:        'Run alternative ad config, if no ad was returned by ad server',
      player_config:                                       'Player config',
      player_config_placeholder:                           'Select video player',
      player_config_secondary_info_autoplay:               'Autoplay',
      player_config_info_ads:                              'ads',
      player_config_info_position:                         'Position in',
      player_config_info_width:                            'Width',
      player_config_info_screen_width:                     'Screen width',
      run_custom_js:                                       'Run custom JavaScript code, if no ad was returned by ad server',
    },
    players_page:              {
      page_header_title:       'Players',
      page_header_subtitle:    'Create and manage your video players, how they look and behave.',
      empty_state_add:         'Add your first player',
      add_new_player_cta:      'Add new player',
      col_t_name:              'Name',
      col_t_ad_type:           'Ad format',
      col_t_aspect_ratio:      'Aspect ratio',
      col_t_ad:                'Ad',
      aspect_ratio_auto:       'Auto',
      ad_type_instream:        'Instream',
      ad_type_outstream:       'Outstream',
      actions_publish:         'Embed',
      col_t_actions:           'Actions',
      actions_edit:            'Edit',
      actions_delete:          'Delete',
      modal_confirm_delete_t:  'Delete',
      modal_confirm_delete_c:  'Are you sure you want to delete',
      modal_confirm_delete_ok: 'player deleted.',

    },
    video_add_page:            {
      video_added: 'Video added.',
    },
    video_edit_page:           {
      changes_saved: 'Changes saved.',
    },
    video_form:                {
      add_links_to_video_files:     'Add links to video files.',
      page_header_title_add:        'New video',
      modal_confirm_delete_t:       'Delete',
      modal_confirm_delete_c:       'Are you sure you want to delete',
      modal_confirm_delete_ok:      'video deleted.',
      page_header_action_delete:    'Delete',
      page_header_action_cancel:    'Cancel',
      page_header_action_save:      'Save',
      input_l_video_file:           'Video file',
      input_l_video_name:           'Video name',
      input_l_video_description:    'Video description',
      input_l_video_categories:     'Video categories',
      input_l_video_poster:         'Video poster URL [only Veedmo Admin]',
      file_uploaded:                'File uploaded.',
      modal_confirm_delete_file:    'Are you sure you want to delete this file?',
      modal_confirm_delete_file_ok: 'File deleted.',
      actions_delete:               'Delete',
      upload_form_1row:             'Click here, or drag file to this area to upload it.',
      upload_form_2row:             'Supported file format: mp4. Maximum upload file size: 100 MB',
      upload_error_file_size:       'Cannot upload the file. File size too large. Maximum upload file size is 100 MB.',
      video_file_required_error:    'Video file is required.',
    },
    video_page:                {
      page_header_title:           'Videos',
      page_header_subtitle:        'Upload and manage your video inventory.',
      empty_state_add:             'Add your first video',
      add_new_video_cta:           'Add new video',
      add_new_video_cta_upload:    'Upload a file',
      add_new_video_cta_register:  'Register URL to a file',
      col_t_name:                  'Name',
      col_t_poster:                'Poster',
      col_t_type:                  'Type',
      col_v_type_external:         'External URL',
      col_v_type_hosted:           'Hosted file',
      col_t_status:                'Status',
      col_t_publish_date:          'Published',
      col_t_create_date:           'Created',
      col_t_actions:               'Actions',
      actions_publish:             'Embed',
      actions_edit:                'Edit',
      actions_generate_poster:     'Generate poster',
      actions_delete:              'Delete',
      modal_confirm_delete_t:      'Delete',
      modal_confirm_delete_c:      'Are you sure you want to delete',
      modal_confirm_delete_ok:     'video deleted.',
      modal_confirm_delete_noname: 'Video deleted.',
      delete_video_in_progress:    'This video is currently being deleted.',
    },
    video_add_media_url_modal: {
      modal_t:                             'Add link to video file',
      modal_cancel_cta:                    'Cancel',
      modal_confirm_cta:                   'Add',
      modal_input_l_file:                  'Video file link',
      modal_input_l_resolution:            'Resolution label',
      modal_select_placeholder_resolution: 'Select video resolution label',
      modal_select_res_option_default:     'Default',
      modal_select_res_option_low:         'Low',
      modal_select_res_option_medium:      'Medium',
      modal_select_res_option_high:        'High',
    },
    video_publish_modal:       {
      modal_t:                           'Generate embed code',
      modal_secondary_info_autoplay:     'Autoplay',
      modal_secondary_info_position:     'Position in',
      modal_cancel_cta:                  'Close',
      modal_notification_code_copied:    'Code snippet copied to clipboard.',
      modal_confirm_cta:                 'Copy code snippet to clipboard',
      modal_input_l_video:               'Video',
      modal_input_l_player:              'Player',
      modal_input_placeholder_player:    'Select video player',
      modal_input_l_code:                'Code snippet',
      modal_secondary_info_width:        'Width',
      modal_secondary_info_screen_width: 'Screen width',
      modal_embed_info_video:            'Video',
      modal_ad_format_instream:          'instream',
    },
    authenticated_page:        {
      home_link:            'Home',
      players_link:         'Players',
      ads_link:             'Ads',
      media_group:          'Media',
      videos_link:          'Videos',
      users_link:           'Veedmo accounts',
      veedmo_settings_link: 'Veedmo settings',
      logout_button:        'Sign out',
      playlists_link:       'Playlists',
      get_embed_code_link:  'Generate embed code',
      embeds_link:          'Embed codes',
      change_password:      'Change Password',
    },
    system_404_page:           {
      message: 'Something went wrong',
      login:   'Sign in to your account',
    },
    register:                  {
      account_exists:  'Account already exists.',
      already_account: 'Already have an account?',
      login_link:      'Sign in',
    },
    generic_form:              {
      required:                          'Required.',
      number:                            'Value must be a number.',
      correct_form:                      'Please correct the form.',
      auth_error:                        'Passwords that you entered is incorrect.',
      password_confirm:                  'The two passwords that you entered do not match!',
      password_validation:               'Password must be minimum 6 characters.',
      validation_url:                    'Must be an URL.',
      placeholder_url:                   'https://www.domain.com/link',
      placeholder_text:                  'Text',
      placeholder_select:                'Select',
      placeholder_password:              'Password',
      msg_info_changes_propagation_time: 'All changes can take up to 1 minute to propagate.',
    },
    generic_common:            {
      request_error: 'There was a problem with request.',
    },
  },
  pl: {},
};
